import React, { useState, useEffect, useRef } from 'react';

import Button from '@helsenorge/designsystem-react/components/Button';
import Chip from '@helsenorge/designsystem-react/components/Chip';
import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import ChevronDown from '@helsenorge/designsystem-react/components/Icons/ChevronDown';
import ChevronUp from '@helsenorge/designsystem-react/components/Icons/ChevronUp';
import PlusLarge from '@helsenorge/designsystem-react/components/Icons/PlusLarge';
import Search from '@helsenorge/designsystem-react/components/Icons/Search';
import LinkList from '@helsenorge/designsystem-react/components/LinkList';
import TagList from '@helsenorge/designsystem-react/components/TagList';

import { useForm } from '@helsenorge/core-cms/userfeedback/utils/useForm';
import { useHover } from '@helsenorge/designsystem-react';

import { getUrlParams, getTimestamp, setUrlParam, getConceptIdFromUrl } from './helpers';
import { ClinicalTrial, getClinicalTrials, RelatertDiagnose } from '../../_api/ClinicalTrialsApi';
import { getSnomedConceptItem, SnomedConcept } from '../../_api/SnomedCTApi';
import { getIconColor } from '../../_helpers/color';
import Dictionary, { DictionaryObject } from '../../_helpers/dictionary';
import { useToggle, KeyCodeEnum } from '../../_helpers/hooks';
import { updateHitsRefLength } from '../../_helpers/refs';
import {
  toUniqueValues,
  stringIsInArray,
  stringEqualsString,
  stringIncludesString,
  sortAlphabetically,
  stringIsInArrayOfObjects,
} from '../../_helpers/string';

const LOCAL_STORAGE_KEY = 'kliniskeStudier';
const ONE_HOUR = 60 * 60;
export const CLINICAL_TRIALS_ID = 'studier';

interface ClinicalTrialsBlockProps {
  title: string;
  clinicalTrialsApiUrl: string;
  snowstormApiUrl: string;
  snomedCTBranch: string;
  dictionary: DictionaryObject;
  trialsPerPage?: number;
}

export interface ClinicalTrialsStorage {
  timestamp: number;
  kliniskeStudier: ClinicalTrial[];
}

export type ClinicalTrialsForm = {
  kategori: string;
  status: string;
  relatertBehandling: string;
  utfortAv: string;
  deltakendeForetak: string;
  query: string;
};

const ClinicalTrialsBlock: React.FunctionComponent<ClinicalTrialsBlockProps> = ({
  title,
  clinicalTrialsApiUrl,
  snowstormApiUrl,
  snomedCTBranch,
  dictionary,
  trialsPerPage = 25,
}) => {
  const [error, setError] = useState('');
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const {
    form: filters,
    handleInputChange,
    changedFields: activeFilters,
    hasChanges: isFiltered,
    resetField,
    resetForm,
    updateFormValues,
  } = useForm<ClinicalTrialsForm>({
    kategori: '',
    status: '',
    relatertBehandling: '',
    utfortAv: '',
    deltakendeForetak: '',
    query: '',
  });
  const [kliniskeStudier, setkliniskeStudier] = useState<ClinicalTrial[]>([]);
  const [concept, setConcept] = useState<SnomedConcept>();

  const { value: isOpen, toggleValue: toggleChange, handleKeyDown } = useToggle(true);

  const handleQueryFilterChange = (): void => {
    // Fake a change event so we can reuse the same method that's used by
    // ordinary change events in the form
    const event = {
      target: {
        name: 'query',
        value: query,
      },
    } as React.ChangeEvent<HTMLInputElement>;

    handleFilterChange(event);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
    setUrlParam(event.target.name as keyof ClinicalTrialsForm, event.target.value);
    handleInputChange(event);
  };

  const resetFilter = (key: keyof ClinicalTrialsForm): void => {
    setUrlParam(key, '');
    resetField(key);
  };

  const handleQueryKeyboardEvent = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.keyCode === KeyCodeEnum.Enter) {
      handleQueryFilterChange();
    }
  };

  useEffect(() => {
    const params = getUrlParams();
    updateFormValues(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const diagnose = getConceptIdFromUrl();
    if (diagnose) {
      getSnomedConceptItem(diagnose, snowstormApiUrl, snomedCTBranch)
        .then(concept => {
          if (concept) {
            toggleChange();
            setConcept(concept);
          }
        })
        .catch(error => setError(error));
    }
    try {
      const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (storedData) {
        const json = JSON.parse(storedData) as ClinicalTrialsStorage;
        if (getTimestamp() - json.timestamp > ONE_HOUR) {
          throw Error('Local storage data has expired');
        }
        if (!json.kliniskeStudier.length) {
          throw Error('Local storage data is invalid');
        }
        setkliniskeStudier(json.kliniskeStudier);
      } else {
        throw Error('Local storage has no data');
      }
    } catch (error) {
      getClinicalTrials(clinicalTrialsApiUrl)
        .then(trials => {
          if (trials) {
            setkliniskeStudier(trials);
          }
        })
        .catch(() => setError(Dictionary(dictionary, '/clinicalTrialsBlock/loadingError', 'Feil: Kunne ikke laste inn kliniske studier')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicalTrialsApiUrl, dictionary, snomedCTBranch, snowstormApiUrl]);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ timestamp: getTimestamp(), kliniskeStudier }));
  }, [kliniskeStudier]);

  const kategorier = kliniskeStudier
    .map(({ kategorier }) => kategorier)
    .flat()
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const statuser = kliniskeStudier
    .map(({ status }) => status)
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const relaterteBehandlinger = kliniskeStudier
    .map(({ relaterte_behandlinger: behandlinger }) => behandlinger ?? [])
    .flat()
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const utfortAv = kliniskeStudier
    .map(({ utfort_av: utfortAv }) => utfortAv)
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const deltakendeForetak = kliniskeStudier
    .map(({ deltakende_foretak: deltakendeForetak }) => deltakendeForetak ?? [])
    .flat()
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const filtrerteKliniskeStudier = kliniskeStudier
    .filter(x => !concept?.id || stringIsInArrayOfObjects<RelatertDiagnose>(concept?.id, x.relaterte_diagnoser, 'diagnose_kode'))
    .filter(x => !filters.status || stringEqualsString(filters.status, x.status))
    .filter(x => !filters.utfortAv || stringEqualsString(filters.utfortAv, x.utfort_av))
    .filter(x => !filters.kategori || stringIsInArray(filters.kategori, x.kategorier))
    .filter(x => !filters.relatertBehandling || stringIsInArray(filters.relatertBehandling, x.relaterte_behandlinger))
    .filter(x => !filters.deltakendeForetak || stringIsInArray(filters.deltakendeForetak, x.deltakende_foretak))
    .filter(x => !filters.query || stringIncludesString(filters.query, `${x.tittel} ${x.sammendrag}`));

  const filtrerteKategorier = filtrerteKliniskeStudier
    .map(({ kategorier }) => kategorier)
    .flat()
    .reduce(toUniqueValues, [] as string[]);

  const filtrerteStatuser = filtrerteKliniskeStudier.map(({ status }) => status).reduce(toUniqueValues, [] as string[]);

  const filtrerteRelaterteBehandlinger = filtrerteKliniskeStudier
    .map(({ relaterte_behandlinger: behandlinger }) => behandlinger ?? [])
    .flat()
    .reduce(toUniqueValues, [] as string[]);

  const filtrerteUtfortAv = filtrerteKliniskeStudier.map(({ utfort_av: utfortAv }) => utfortAv).reduce(toUniqueValues, [] as string[]);

  const filtrerteDeltakendeForetak = filtrerteKliniskeStudier
    .map(({ deltakende_foretak: deltakendeForetak }) => deltakendeForetak ?? [])
    .flat()
    .reduce(toUniqueValues, [] as string[]);

  const linkRefList = useRef<React.RefObject<HTMLLIElement>[]>([React.createRef()]);

  linkRefList.current = updateHitsRefLength<HTMLLIElement>(filtrerteKliniskeStudier.length);

  const moreTrials = filtrerteKliniskeStudier.length > page * trialsPerPage;

  const handleShowMoreTrials = (): void => {
    setPage(page + 1);
  };

  useEffect(() => {
    // Set focus to the first of the newly added trials
    linkRefList?.current[(page - 1) * trialsPerPage]?.current?.focus();
  }, [page, trialsPerPage]);

  const { hoverRef, isHovered } = useHover<HTMLDivElement>();

  if (error) return <p className="paragraph">{error}</p>;

  return (
    <div className="default-block-spacing" id={CLINICAL_TRIALS_ID}>
      <div className="mb-6">
        <div
          className="row bg-blueberry50 has-hover-bg"
          ref={hoverRef}
          role="button"
          onClick={(e): void => toggleChange(e)}
          onKeyDown={(e): void => handleKeyDown(e)}
          aria-expanded={isOpen}
          tabIndex={0}
        >
          <div className="col-2 col-md-1 d-flex justify-content-center py-5">
            <Icon
              size={IconSize.XSmall}
              color={getIconColor('blueberry')}
              svgIcon={isOpen ? ChevronUp : ChevronDown}
              isHovered={isHovered}
            />
          </div>
          <div className="col-10 d-flex align-items-center text-blueberry600">{title}</div>
        </div>
        {isOpen && (
          <div className="row bg-blueberry50 pt-7 pb-8">
            <div className="col-12 col-md-10 offset-md-1">
              <div className="d-flex justify-content-between align-items-center mb-6">
                <label htmlFor="clinical-trials-query" className="sr-only">
                  {Dictionary(dictionary, '/common/search', 'Search')}
                </label>
                <input
                  autoComplete="off"
                  id="clinical-trials-query"
                  type="text"
                  name="query"
                  value={query}
                  onChange={(e): void => setQuery(e.target.value)}
                  onKeyDown={handleQueryKeyboardEvent}
                  className="form-input form-input--compact mr-3"
                />
                <Button onClick={handleQueryFilterChange}>
                  <Icon svgIcon={Search} />
                  {Dictionary(dictionary, '/common/search', 'Search')}
                </Button>
              </div>
            </div>
            <div className="col-12 col-md-10 offset-md-1">
              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="kategori" className="sr-only">
                    {Dictionary(dictionary, '/clinicalTrials/category', 'Category')}
                  </label>
                  <select
                    id="kategori"
                    name="kategori"
                    value={filters.kategori}
                    onBlur={handleFilterChange}
                    onChange={handleFilterChange}
                    className="form-select form-select--compact mb-6"
                  >
                    <option value="">{Dictionary(dictionary, '/clinicalTrials/category', 'Category')}</option>
                    {kategorier.map(kategori => (
                      <option key={kategori} disabled={!filtrerteKategorier.includes(kategori)}>
                        {kategori}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="status" className="sr-only">
                    {Dictionary(dictionary, '/clinicalTrials/status', 'Status')}
                  </label>
                  <select
                    id="status"
                    name="status"
                    value={filters.status}
                    onBlur={handleFilterChange}
                    onChange={handleFilterChange}
                    className="form-select form-select--compact mb-6"
                  >
                    <option value="">{Dictionary(dictionary, '/clinicalTrials/status', 'Status')}</option>
                    {statuser.map(status => (
                      <option key={status} disabled={!filtrerteStatuser.includes(status)}>
                        {status}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="relatertBehandling" className="sr-only">
                    {Dictionary(dictionary, '/clinicalTrials/relevantTreatment', 'Relevant treatment')}
                  </label>
                  <select
                    id="relatertBehandling"
                    name="relatertBehandling"
                    value={filters.relatertBehandling}
                    onBlur={handleFilterChange}
                    onChange={handleFilterChange}
                    className="form-select form-select--compact mb-6"
                  >
                    <option value="">{Dictionary(dictionary, '/clinicalTrials/relevantTreatment', 'Relevant treatment')}</option>
                    {relaterteBehandlinger.map(behandling => (
                      <option key={behandling} disabled={!filtrerteRelaterteBehandlinger.includes(behandling)}>
                        {behandling}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="utfortAv" className="sr-only">
                    {Dictionary(dictionary, '/clinicalTrials/responsibleOrganization', 'Responsible organization')}
                  </label>
                  <select
                    id="utfortAv"
                    name="utfortAv"
                    value={filters.utfortAv}
                    onBlur={handleFilterChange}
                    onChange={handleFilterChange}
                    className="form-select form-select--compact mb-6"
                  >
                    <option value="">
                      {Dictionary(dictionary, '/clinicalTrials/responsibleOrganization', 'Responsible organization')}
                    </option>
                    {utfortAv.map(foretak => (
                      <option key={foretak} disabled={!filtrerteUtfortAv.includes(foretak)}>
                        {foretak}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="deltakendeForetak" className="sr-only">
                    {Dictionary(dictionary, '/clinicalTrials/studyTakesPlaceAt', 'Study takes place at')}
                  </label>
                  <select
                    id="deltakendeForetak"
                    name="deltakendeForetak"
                    value={filters.deltakendeForetak}
                    onBlur={handleFilterChange}
                    onChange={handleFilterChange}
                    className="form-select form-select--compact"
                  >
                    <option value="">{Dictionary(dictionary, '/clinicalTrials/studyTakesPlaceAt', 'Study takes place at')}</option>
                    {deltakendeForetak.map(foretak => (
                      <option key={foretak} disabled={!filtrerteDeltakendeForetak.includes(foretak)}>
                        {foretak}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {(concept || isFiltered) && (
        <div className="row bg-neutral50 mb-6 py-7">
          <div className="col-12 col-md-10 offset-md-1">
            <span className="mr-5">{Dictionary(dictionary, '/clinicalTrials/searchFilteredBy', 'Search filtered by')}</span>
            <TagList>
              {concept && (
                <Chip action={'remove'} onClick={(): void => setConcept(undefined)}>
                  {concept.pt.term}
                </Chip>
              )}
              {activeFilters.map(key => (
                <Chip action={'remove'} onClick={(): void => resetFilter(key)} key={key}>
                  {filters[key]}
                </Chip>
              ))}
              <Chip
                action="undo"
                onClick={(): void => {
                  resetForm();
                  setConcept(undefined);
                }}
              >
                {Dictionary(dictionary, '/clinicalTrials/resetFilters', 'Reset filters')}
              </Chip>
            </TagList>
          </div>
        </div>
      )}
      <div className="row bg-neutral50 pt-7 pb-8">
        <div className="col-12 col-md-10 offset-md-1">
          <p className="mt-0 mb-6 mb-md-7" role="alert" aria-live="polite">
            <strong>
              {filtrerteKliniskeStudier.length}{' '}
              {filtrerteKliniskeStudier.length === 1
                ? Dictionary(dictionary, '/clinicalTrials/singleClinicalTrial', 'clinical trial')
                : Dictionary(dictionary, '/clinicalTrials/multipleClinicalTrials', 'clinical trials')}
            </strong>
          </p>
          {filtrerteKliniskeStudier.length > 0 && (
            <LinkList color="neutral">
              {filtrerteKliniskeStudier.slice(0, page * trialsPerPage).map((kliniskStudie, index) => (
                <LinkList.Link key={kliniskStudie.id} ref={linkRefList.current[index]} href={kliniskStudie.lenke}>
                  {kliniskStudie.tittel}
                </LinkList.Link>
              ))}
            </LinkList>
          )}
          {moreTrials && (
            <Button concept="normal" variant="outline" onClick={handleShowMoreTrials} wrapperClassName="mt-6 ml-3 ml-md-9">
              <Icon svgIcon={PlusLarge} />
              {Dictionary(dictionary, '/common/showMore', 'Show more')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClinicalTrialsBlock;
